import logoSignIn from '../assets/logoTrionix.png'
import axios from 'axios';

export const palette = {
  primary:{
    main:'#0847a7'
  },
  secondary:{
    main:'#4466f2'
  }
}

export const getBatteryPercentage = (counts) => {
  return (-(0.609209*Math.pow(counts,5))+(88.276908*Math.pow(counts,4))-(5111.694563*Math.pow(counts,3))+(147848.193799*Math.pow(counts,2))-2135910.527044*counts+12329269.492005).toFixed(0)
}


export const drawerWidth = 145;
export const closedDrawerWidth = 0;

const veea_cpu = "https://veea00001-trionixtech.pagekite.me/api"
const trionix_cpu = "https://txserver00001-trionixtech.pagekite.me/api"

let global_url = trionix_cpu

export const image_path = global_url
export const imgServerS3 = "http://celudoc-dev-public.s3-website.us-east-2.amazonaws.com/";
export const rfcImgServerS3 = "https://s3.us-east-2.amazonaws.com/asiconnect3-public/business/image";

export const SignIn = {
  logo: logoSignIn,
  spinner: {
    color: '#0c519d'
  }
}

export const SideBar = {
  width: drawerWidth,
  minWidth: closedDrawerWidth,
}

export const TopBar = {
  width: drawerWidth,
  minWidth: closedDrawerWidth,
}

export const Footer = {

}

export const Languages = [
  {id:2, code:'MX', language:'spanish'},
  {id:1, code:'US', language:'english'},
];

export const mainServer = {
  instance: axios.create({
    //baseURL: "http://192.168.100.178:3000",
    //baseURL: "http://192.168.100.178:8080/api",
    //baseURL: "http://192.168.100.33:80/api",
    //baseURL: "https://veea00001-trionixtech.pagekite.me/api",
    baseURL: global_url,
    headers: {'Content-Type': 'application/json'},
  }),
  credentials: {
    user: 'trionix',
    password: 'tUUmcZ5FwL95AMu6UfZj9KeTCpuHA8ES'
  }
}

export const s3Server = {
  instance: axios.create({
    baseURL: "https://s3.us-east-2.amazonaws.com/asiconnect3-public/user/image",
    headers: {'Access-Control-Allow-Origin': '*'},
  }),
}
