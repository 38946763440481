export const content = {
  title:'Add zone',
  nodata:'No data',
  error:'No data',
  button:'Add',
  cancelButton:'Cancel',
  form:{
    device_id:{
      label:'Device',
      placeholder:'Select a device',
      helper:'Invalid device'
    },
  },
}