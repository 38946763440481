import React, {Component} from 'react'
import { Grid, Typography, TableRow,TableCell, Icon, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import {TableItem} from '../../../../../components/DisplayData/DisplayData'

import {onFilterDataArray} from './customFunctions'
import { green, red, grey } from '@material-ui/core/colors';
import { CellSensorImage, CellSensorPower, CellSensorStatus, CellSensorData } from '../../../../../components/DisplayData/TableItems';


const styles = (theme) => ({
  root:{},
  tablecell:{
    borderTop:`2px solid ${grey[200]}`,
    padding:'16px 16px',
    border:0,
    '&:hover':{
      cursor:'pointer'
    }
  },
  tablerow:{
    '&:hover':{
      background:'rgba(0,0,0,0.05)'
    }
  }
})

class DeviceList extends Component{

  state = {
    rowsPerPage:25,
    page:0,
    searchText:'',
  }

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value,10)
    //this.setState({rowsPerPage:value})
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.rowsPerPage = value
    onChangeConfig(temp)
  }

  handleChangePage = (event, newPage) => {
    //this.setState({page:newPage})
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.page = newPage
    onChangeConfig(temp)
  }

  onSearch = (value) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.searchText = value
    onChangeConfig(temp)
  }

  onChangeFilter = (item) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.filter = item.id
    onChangeConfig(temp)

  }
  
  onChangeMasterFilter = (key, data) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.filter[key] = data
    onChangeConfig(temp)

  }

  render(){

    const {classes, title, header, data, onUserSelected, onAddPatient, onDeleteUser, 
      filters, config, master_filters
    } = this.props
    const {filter, page, rowsPerPage, totalItems, isSearch} = config

    return(
      
      <div className={classes.root}>
        <SimpleTable
          title={title}
          header={header}
          isSearch={isSearch}
          filter={config.filter}
          filters={filters}
          master_filters={master_filters}
          onChangeFilter={this.onChangeFilter}
          onChangeMasterFilter={this.onChangeMasterFilter}
          rowsPerPage={rowsPerPage}
          page={page}
          rows={totalItems}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onSearch={this.onSearch}
          addItem={false}
          onAddItem={onAddPatient}
        >
          {data.map((item,key)=>{
            return(
              <TableRow key={key.toString()} 
              className={classes.tablerow} 
              >
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <CellSensorImage type={item ? item.item.model.type : 'sensor'}/>
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item ? item.item.name : null}
                    error={'No data'}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item ? item.item.identifier : null}
                    error={'No data'}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item ? item.item.model.name : null}
                    error={'No data'}
                  />
                </TableCell>

                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <CellSensorPower 
                    data={item.item}
                    error={'No data'}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <CellSensorStatus 
                    data={item.item}
                    error={'No data'}
                  />
                </TableCell>   
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <CellSensorData 
                    data={item.item}
                    error={'No data'}
                  />
                </TableCell>               
              </TableRow>
            )
          })}
        </SimpleTable>
      </div>
    )
  }
}

export default withStyles(styles)(DeviceList)