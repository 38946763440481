import React, {Component} from 'react'
import {
  Icon,
  Grid,
} from '@material-ui/core'
import {grey, blue, red, amber, lightBlue, green} from '@material-ui/core/colors'
import ContentText from '../Texts/ContentText';
import Caption from '../Texts/Caption';

import controller from '../../assets/devices/controller.png'
import sensor from '../../assets/devices/sensor.png'


import temperature_icon from '../../assets/devices/thermometer.png'
import humidity_icon from '../../assets/devices/humidity.png'
import presence_on_icon from '../../assets/devices/human.png'
import presence_off_icon from '../../assets/devices/humanEmpty.png'
import door_on_icon from '../../assets/devices/openblue.png'
import door_off_icon from '../../assets/devices/closeblue.png'

import moment from 'moment-timezone'


const timezone_data = "America/Mexico_City"


export const CellSensorImage = (props) => {

  const {type} = props

  let imageData = null
  switch(type){
    case 'Sensor':
      imageData = sensor
      break;
    case 'Control':
    case 'Controller':
      imageData = controller
      break;
    default:
      break;
  }

  return(
    <Grid container>
      <Grid item>
        <img src={imageData} alt='' style={{width:36}}/>
      </Grid>
    </Grid>
  )
}

export const CellSensorPower = (props) => {

  const {data} = props

  let icon_name = 'flash_on'
  let icon_color = amber[700]
  let number = '0 %'
  if(data){
    switch(data.power){
    
      case 'AC':
        icon_name = 'flash_on'
        icon_color = amber[700]
        number = '100 %'
        break;
      case 'Battery':
      case 'DC':
        const counts = data.data.battery.value
        let percentage = 0.00
        if(counts > 26.1 && counts < 34)
          percentage =  -(0.609209*Math.pow(counts,5))+(88.276908*Math.pow(counts,4))-(5111.694563*Math.pow(counts,3))+(147848.193799*Math.pow(counts,2))-2135910.527044*counts+12329269.492005
        icon_name = 'battery_charging_full'
        icon_color = green[700]
        number = percentage.toFixed(0) + "%"
        break;
      default:
        break;
    }
  }
  

  return(
    <Grid container wrap='nowrap'>
      <Grid item>
        <Icon style={{color:icon_color}}>{icon_name}</Icon>
      </Grid>
      <Grid item xs>
        <ContentText text={number} medium noWrap/>
      </Grid>
    </Grid>
  )
}

export const CellSensorStatus = (props) => {

  const {data} = props

  let icon_name = 'flash_on'
  let icon_color = green[700]
  let status_label = 'Online'
  

  const timestamp = data.data.last_seen.value 

  const sensor_time = moment(timestamp).tz(timezone_data)
  const current_time = moment().tz(timezone_data)

  if(current_time.diff(sensor_time) < 60*60*1000){
    status_label = 'Online'
    icon_color = green[700]
  }else{
    status_label = 'Offline'
    icon_color = red[700]
  }
  

  return(
    <Grid container wrap='nowrap' spacing={1} alignItems='center'>
      <Grid item>
        <Icon style={{color:icon_color, fontSize:12}}>brightness_1</Icon>
      </Grid>
      <Grid item xs>
        <ContentText text={status_label} medium noWrap/>
      </Grid>
    </Grid>
  )
}

export const CellSensorData = (props) => {

  const {data} = props
  
  let contentData = null


  if(data){
    if(data.model.type === 'Controller' || data.model.type === 'Control'){

      if(data.model.name !== 'Lights Switch' && data.model.name !== 'Lights Dimming'){
        const input_array = [
          data.data.digital_input_1 ? data.data.digital_input_1.value : 0,
          data.data.digital_input_2 ? data.data.digital_input_2.value : 0,
          data.data.digital_input_3 ? data.data.digital_input_3.value : 0,
          data.data.digital_input_4 ? data.data.digital_input_4.value : 0, 
        ]
  
        const output_array = [
          data.data.digital_output_1 ? data.data.digital_output_1.value : 0,
          data.data.digital_output_2 ? data.data.digital_output_2.value : 0,
          data.data.digital_output_3 ? data.data.digital_output_3.value : 0,
          data.data.digital_output_4 ? data.data.digital_output_4.value : 0, 
        ]
  
        contentData = (
          <Grid container spacing={4} wrap='nowrap'>
            <Grid item>
              <DigitalSignalsWrapper title='Inputs' data={input_array}/>
            </Grid>
            <Grid item>
              <DigitalSignalsWrapper title='Outputs' data={output_array}/>
            </Grid>
          </Grid>
        )
      }else{
        if(data.model.name !== 'Lights Dimming'){
          contentData = (
            <Grid container alignItems='center' wrap='nowrap' spacing={1}>
              <Grid item>
                <Icon style={{
                  paddingTop:8,
                  color:data.data.value.value ? amber[700] : grey[400]
                }}>wb_incandescent</Icon>
              </Grid>
              <Grid item xs>
                <ContentText text={data.data.value.value ? 'ON' : 'OFF'} medium noWrap/>
              </Grid>
            </Grid>
          )
        }else{
          contentData = (
            <Grid container alignItems='center' wrap='nowrap' spacing={1}>
              <Grid item>
                <Icon style={{
                  paddingTop:8,
                  color:data.data.value.value ? amber[700] : grey[400]
                }}>wb_incandescent</Icon>
              </Grid>
              <Grid item xs>
                <ContentText text={(data.data.value.value*100).toFixed(0)+'%'} medium noWrap/>
              </Grid>
            </Grid>
          )
        }
      }

      
    }else{ /////////// SENSOR DATA
      switch(data.model.name){
        case 'Temperature/Humidity':
          contentData = (
            <Grid container spacing={2} wrap='nowrap'>
              <Grid item>
                <SensorWrapper 
                  type='temperature'
                  data={data.data.temperature}/>
              </Grid>
              <Grid item>
                <SensorWrapper 
                  type='humidity'
                  data={data.data.humidity}/>
              </Grid>
            </Grid>
          )
          break;
        case 'Presence/Motion':
          contentData = (
            <Grid container spacing={2} wrap='nowrap'>
              <Grid item>
                <SensorWrapper 
                  type='presence'
                  data={data.data.presence}/>
              </Grid>
            </Grid>
          )
          break;
        case 'Open/Close':
          contentData = (
            <Grid container spacing={2} wrap='nowrap'>
              <Grid item>
                <SensorWrapper 
                  type='open/close'
                  data={data.data.opening}/>
              </Grid>
            </Grid>
          )
          break;
        default:
          break;
      }
    }
  }

  return(
    <div>
      {contentData}
    </div>
  )
}


const DigitalSignalsWrapper = (props) => {

  const {title, data} = props



  return(
    <Grid container alignItems='center' direction='column'>
      <Grid item>
        <Caption text={title ? title : ''} medium/>
      </Grid>
      <Grid item>
        <Grid container spacing={1} wrap='nowrap'>
          {data.map((item,key)=>{
            return(
              <Grid item key={key.toString()}>
                <Icon style={{color:item ? green[700] : red[700], fontSize:12}}>brightness_1</Icon>
              </Grid>
            )
          })}
        </Grid>
      </Grid>

    </Grid>
  )
}

const SensorWrapper = (props) => {

  const {type, data} = props

  let imagecontent = null
  let textcontent = ''

  switch(type){
    case 'temperature':
      imagecontent = temperature_icon
      textcontent = data ? data.value.toFixed(1) + ' ' +data.unit : ''
      break;
    case 'humidity':
      imagecontent = humidity_icon
      textcontent = data ? data.value.toFixed(1) + ' ' +data.unit : ''
      break;
    case 'presence':
      imagecontent = data.value ?  presence_on_icon : presence_off_icon
      textcontent = data.value ? 'Occupied' : 'Empty'
      break;
    case 'open/close':
      imagecontent = data.value ?  door_on_icon : door_off_icon
      textcontent = data.value ? 'Opened' : 'Closed'
      break;
    default:
      break;
  }

  return(
    <Grid container alignItems='center' wrap='nowrap' spacing={1}>
      <Grid item>
        <img src={imagecontent} alt='' style={{width:24}}/>
      </Grid>
      <Grid item xs>
        <ContentText text={textcontent} medium noWrap/>
      </Grid>
    </Grid>
  )
}


const SensorWrapper2 = (props) => {

  const {type, data} = props

  let imagecontent = null
  let textcontent = ''

  switch(type){
    case 'temperature':
      imagecontent = temperature_icon
      textcontent = data ? data.value.toFixed(1) + ' ' +data.unit : ''
      break;
    case 'humidity':
      imagecontent = humidity_icon
      textcontent = data ? data.value.toFixed(1) + ' ' +data.unit : ''
      break;
    case 'presence':
      imagecontent = data.value ?  presence_on_icon : presence_off_icon
      textcontent = data.value ? 'Occupied' : 'Empty'
      break;
    case 'open/close':
      imagecontent = data.value ?  door_on_icon : door_off_icon
      textcontent = data.value ? 'Opened' : 'Closed'
      break;
    default:
      break;
  }

  return(
    <Grid container alignItems='center' wrap='nowrap' spacing={1}>
      <Grid item>
        <img src={imagecontent} alt='' style={{width:24}}/>
      </Grid>
      <Grid item xs>
        <ContentText text={textcontent} medium noWrap/>
      </Grid>
    </Grid>
  )
}