import React, { Component } from 'react';
import { TableHead, Table, TableBody, TableRow, TableCell, IconButton, Icon, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ContentText from '../../../../../../../components/Texts/ContentText';
import Subtitle from '../../../../../../../components/Texts/Subtitle';
import { CellSensorData } from '../../../../../../../components/DisplayData/TableItems';
import cctvImage from '../../../../../../../assets/devices/security-camera.png';
import { grey, blue } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles';

const header = [
  {id:0,name:'ID', align:'center', padding:'checkbox'},
  {id:1,name:'Name', padding:null},
  {id:1,name:'Identifier', padding:null},
  {id:2,name:'Data', padding:'checkbox'},
  {id:3,name:'Visible', align:'center', padding:'checkbox'},

]

class TableDevices extends Component {
  render(){

    const {classes, data, onChangeVisibility, selectedTab, cameras} = this.props

    if(selectedTab !== 'CCTV'){
      return(
        <Table>
          <TableHead>
            <TableRow>
              {header.map((item,key)=>{
                return(
                  <TableCell 
                  align={data.align ? data.align : 'left'}
                  key={key.toString()} 
                  padding={item.padding} 
                  className={classes.headerCell} >
                    <ContentText text={item.name} medium variant='light'/>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item,key)=>{
  
              let iconName = item.visible ? 'visibility' : 'visibility_off'
              let iconColor = item.visible ? blue[900] : grey[400]
  
              return(
                <TableRow key={key.toString()} className={classes.bodyRow}>
                  <TableCell className={classes.bodyCell} padding='checkbox'>
                    <ContentText text={item.id} medium  />
                  </TableCell>
                  <TableCell className={classes.bodyCell}>
                    <ContentText text={item.device.name} medium />
                  </TableCell>
                  <TableCell className={classes.bodyCell}>
                    <ContentText text={item.device.identifier} medium />
                  </TableCell>
                  <TableCell className={classes.bodyCell} style={{paddingLeft:0}}>
                    <CellSensorData data={item.device}/>
                  </TableCell>
                  <TableCell className={classes.bodyCell} >
                    <IconButton size='small' onClick={() => onChangeVisibility(item, 'device')}>
                      <Icon style={{color:iconColor}}>{iconName}</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )
    }

    return(
      <Table>
        <TableHead>
          <TableRow>
            {header.map((item,key)=>{
              return(
                <TableCell 
                align={data.align ? data.align : 'left'}
                key={key.toString()} 
                padding={item.padding} 
                className={classes.headerCell} >
                  <ContentText text={item.name} medium variant='light'/>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {cameras.map((item,key)=>{

            let iconName = item.visible ? 'visibility' : 'visibility_off'
            let iconColor = item.visible ? blue[900] : grey[400]

            return(
              <TableRow key={key.toString()} className={classes.bodyRow}>
                <TableCell className={classes.bodyCell} padding='checkbox'>
                  <ContentText text={item.order} medium  />
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  <ContentText text={item.camera.name} medium />
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  <ContentText text={item.camera.identifier} medium />
                </TableCell>
                <TableCell className={classes.bodyCell} style={{paddingLeft:0}}>
                  <ContentText text={item.camera.ip} medium />
                </TableCell>
                <TableCell className={classes.bodyCell} >
                  <IconButton size='small' onClick={() => onChangeVisibility(item, 'camera')}>
                    <Icon style={{color:iconColor}}>{iconName}</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )

    
  }
}

const styles = theme => ({
  root:{},
  headerCell:{
    border:0,
  },
  bodyCell:{
    border:0,
    borderTop:`2px solid ${grey[100]}`
  },
  bodyRow:{
    '&:hover':{
      cursor:'pointer',
      background: fade(blue[600],0.08)
    }
  },
  image:{
    width:100
  }
})

export default withStyles(styles)(TableDevices)