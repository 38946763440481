import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { grey, blueGrey } from '@material-ui/core/colors'
import { Grid, Paper, OutlinedInput, Typography, InputAdornment, Icon, IconButton,
  Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TablePagination  } from '@material-ui/core'
import CardWrapper from '../Cards/CardWrapper/CardWrapper';
import ContentText from '../Texts/ContentText';
import GenericPopper from '../Poppers/GenericPopper';
import ItemSelection from '../Inputs/ItemSelection/ItemSelection';


const styles_input = (theme) => ({
  root: {
    position: 'relative',
    color:grey[700],
    '& $notchedOutline': {
      borderRadius:'40px',
      borderWidth: 0,
    },
    fontSize:14,
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        borderColor:'blue',
      },
    },
    '&$focused $notchedOutline': {
      borderWidth: 2,
    },
    '&$error $notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '&$disabled $notchedOutline': {
      borderColor: theme.palette.action.disabled,
    },
  },
  /* Styles applied to the root element if the component is focused. */
  focused: {},
  /* Styles applied to the root element if `disabled={true}`. */
  disabled: {},
  /* Styles applied to the root element if `error={true}`. */
  error: {},
  /* Styles applied to the `NotchedOutline` element. */
  notchedOutline: {},
  input:{
  },
  adornedStart:{
    background:'white',
    borderRadius:'40px',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',

  }
})

class SearchInputW extends Component{

  onChange = (event) => {
    const {onSearch} = this.props
    onSearch(event.currentTarget.value)
  }

  render(){
    const {classes} = this.props

    return(
      <OutlinedInput 
        margin='dense'
        placeholder='Buscardor...'
        classes={classes}
        onChange={this.onChange}
        startAdornment={<InputAdornment>
          <Icon style={{color: grey[500],paddingRight:'4px'}}>search</Icon>
        </InputAdornment>}
      />
    )
  }
}

const SearchInput = withStyles(styles_input)(SearchInputW);

const styles_toolbar = (theme) => ({
  addbutton:{
    background:theme.palette.secondary.main,
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    color:'white',
    '&:hover':{
      background:theme.palette.secondary.dark,
    }
  }
})

class TableToolbarW extends Component {
  render(){
    const {classes, onSearch} = this.props

    

    return(
      <Grid container justify='flex-end' spacing={2}>
        <Grid item>
          <SearchInput onSearch={onSearch}/>
        </Grid>
      </Grid>
    )
  }
}

const TableToolbar = withStyles(styles_toolbar)(TableToolbarW)

const styles_filter = (theme) => ({
  root:{},
  button:{
    padding:'8px 16px',
    borderRadius:'40px',
    background:'white',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    '&:hover':{
      cursor:'pointer'
    }
  },
  icon:{
    paddingTop:3,
    color:grey[500],
    fontSize:24
  }
})

class FilterToolbarW extends Component {

  state = {
    open:false,
    anchorEl:null
  }


  onCloseMenu = () => {
    this.setState({open: false,anchorEl: null});
  };
 

  onOpenMenu = event => {
    const { currentTarget } = event;
    this.setState({anchorEl: currentTarget,open: true});
  }

  onSelectedItem = (item) => {
    const {onChangeFilter} = this.props
    this.onCloseMenu()
    onChangeFilter(item)
  }
  


  render(){

    const {classes, filter, filters, placeholder} = this.props
    const {open, anchorEl} = this.state

    //console.log(filters)

    let filterContent = null
    if(filter){
      if(filter.id !== null){
        filterContent = (
          <ContentText text={filter.label}/>
        )
      }else{
        filterContent = (
          <ContentText text={placeholder} variant='light'/>
        )
      }
    }else{
      filterContent = (
        <ContentText text={placeholder} variant='light'/>
      )
    }

    return(
      <div>
        <GenericPopper 
          anchorEl={anchorEl}
          open={open}
          onClickAway={this.onCloseMenu}
          onSelectedItem={this.onSelectedItem}
          menu={filters}
        />
        <div>
          <Grid container>
            <Grid item>
              <div className={classes.button} onClick={this.onOpenMenu}>
                <Grid container alignItems='center' spacing={2}>
                  <Grid item>
                    <div style={{minWidth:'100px'}}>
                      {filterContent}
                    </div>
                    
                  </Grid>
                  <Grid item>
                    <Icon className={classes.icon} >keyboard_arrow_down</Icon>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

const FilterToolbar = withStyles(styles_filter)(FilterToolbarW)

const styles = (theme) => ({
  root:{

  },
  container:{
    boxSizing:'border-box',
    padding:'32px',
  },
  title:{
    color:theme.palette.primary.main
  },
  headerCell:{
    color:theme.palette.primary.main,
    border:0,
  },
  headerText:{
    fontWeight:'500'
  },
  table:{
    minWidth:500
  },
  tableWrapper:{
    overflowX: 'auto',
  },
  addbutton:{
    background:theme.palette.secondary.main,
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    color:'white',
    '&:hover':{
      background:theme.palette.secondary.dark,
    }
  }
  

})

class SimpleTable extends Component {

  state = {
    rowsPerPage: 5,
    rows: 50,
    page:0,
    setPage:0
  }

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value,10)
    this.setState({rowsPerPage:value})
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage})
  }


  onChangeFilter = (id,data) => {
    console.log(id)
    console.log(data)
  }
  

  render(){

    const  {classes, header, data, title, onSearch, rowsPerPage, 
      rows, page, onChangePage, onChangeRowsPerPage, addItem, onAddItem,
      filter, isSearch, filters, onChangeFilter, statusFilter, 
      master_filters, onChangeMasterFilter, noFooter
    } = this.props

   // console.log(filter)

    let searchToolbarContent = null
    if(isSearch){
      searchToolbarContent = (
        <Grid item>
          <TableToolbar 
            onSearch={onSearch}
            addItem={addItem}
            onAddItem={onAddItem}
          />
        </Grid>
      )
    }

    let filtersContent = null
    if(master_filters){
      filtersContent = master_filters.map((item,key)=>{
        return(
          <Grid item key={key.toString()}>
            <FilterToolbar 
              id={key}
              placeholder={item.placeholder}
              filter={filter[item.id]}
              filters={item.filters}
              onChangeFilter={(data) => onChangeMasterFilter(item.id,data)}
            />
          </Grid>
        )
      })
    }

    let addButton = null
    if(addItem){
      addButton = <Grid item>
        <IconButton onClick={onAddItem} className={classes.addbutton}>
          <Icon>add</Icon>
        </IconButton>
      </Grid>
    }

    return(
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justify='flex-end' alignItems='center' spacing={2}>
              {filtersContent}
              {searchToolbarContent}
              {addButton}

            </Grid>
          </Grid>
          <Grid item xs={12}>

          </Grid>
        </Grid>
        <CardWrapper>
          <div className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <div className={classes.tableWrapper}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            {header.map((item,key)=>{
                              return(
                                <TableCell className={classes.headerCell} key={key.toString()}>
                                  <Typography variant='body2' className={classes.headerText}>
                                    {item}
                                  </Typography>
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.props.children}
                        </TableBody>
                        

                      </Table>
                    </div>
                    
                    
                  </Grid>
                  {!noFooter ? (
                    <Grid item xs={12}>
                      <Grid container justify='flex-end'>
                        <Grid item>
                        <div className={classes.tableWrapper}>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                style={{border:0}}
                                rowsPerPageOptions={[5, 10, 25]}
                                count={rows}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                  inputProps: { 'aria-label': 'Rows per page' },
                                  native: true,
                                }}
                                onChangePage={onChangePage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                              />
                            </TableRow>
                          </TableFooter>
                        </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  
                </Grid>
                
              </Grid>
            </Grid>
            
            

          </div>

        
        </CardWrapper>
        
      </div>
    )
  }
}

export default withStyles(styles)(SimpleTable)